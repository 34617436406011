<template>
  <div class="bottom-nav">
    <v-bottom-navigation :value="value" color="accent" grow>
      <v-btn
        v-for="item in bottom_nav_items"
        :key="item.title"
        @click="value === item.id ? null : $router.push({ name: item.to })"
      >
        <span>{{ item.title }}</span>
        <icon :name="item.icon" file="feather" class="is-20x20"></icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 0,
      bottom_nav_items: [
        { id: 0, icon: "home", title: "Home", to: "Home" },
        { id: 1, icon: "command", title: "Event", to: "Events" },
        { id: 2, icon: "briefcase", title: "Finance", to: "Finance" },
        { id: 3, icon: "settings", title: "Settings", to: "Settings" },
      ],
    };
  },
  mounted() {
    this.setActiveItem();
  },
  watch: {
    "$route.name": {
      handler() {
        this.setActiveItem();
      },
    },
  },
  methods: {
    setActiveItem() {
      if (this.$route.name === "Home") {
        this.value = 0;
      } else if (this.$route.name === "Events") {
        this.value = 1;
      } else if (this.$route.name === "Finance") {
        this.value = 2;
      } else if (this.$route.name === "Settings") {
        this.value = 3;
      } else {
        this.value = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
