<template>
  <div>
    <top-navbar />
    <div class="py-14">
      <router-view></router-view>
    </div>
    <bottom-navbar />
  </div>
</template>

<script>
import TopNavbar from "@/components/shared/ui/navbar/top-navbar.vue";
import BottomNavbar from "@/components/shared/ui/navbar/bottom-navbar.vue";
export default {
  components: {
    "top-navbar": TopNavbar,
    "bottom-navbar": BottomNavbar,
  },
};
</script>

<style lang="scss" scoped></style>
