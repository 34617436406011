<template>
  <div>
    <v-app-bar app color="primary" dark>
      <!-- <icon
        @click.native="drawer = !drawer"
        name="menu"
        file="feather"
        class="is-24x24"
      ></icon> -->

      <v-toolbar-title>
        {{ $route.meta.navbar_title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <button @click="logout">Logout</button> -->
      <icon
        @click.native="logout"
        name="log-out"
        file="feather"
        class="is-24x24 white-text"
      ></icon>
    </v-app-bar>
    <!-- <v-navigation-drawer v-model="drawer" fixed temporary>
      testing
    </v-navigation-drawer> -->
  </div>
</template>

<script>
import { auth, signOut } from "@/firebaseconfig.js";
export default {
  methods: {
    logout() {
      this.is_loading = true;
      signOut(auth).then(() => {
        this.$router.replace({
          name: "Login",
        });
        this.is_loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
